import { Kuchikomi } from '../model/Kuchikomi'
import { CurrentUser } from '../recoil/auth/type'

export const checkDisplayReplyButton = (
  user: CurrentUser,
  kuchikomi: Kuchikomi
) => {
  if (user.isKuchikomiUser()) {
    return true
  } else if (user.isEnterpriseUser()) {
    if (
      user.kuchikomiEnterpriseId &&
      // user.isPaidPlan &&
      user.kuchikomiEnterpriseId.toString() === kuchikomi.revieweeEnterprise.id
    ) {
      return true
    }
  }

  return false
}

export const checkEnableReply = (user: CurrentUser, kuchikomi: Kuchikomi) => {
  if (user.isKuchikomiUser()) {
    return true
  } else if (user.isEnterpriseUser()) {
    if (
      user.kuchikomiEnterpriseId &&
      user.isPaidPlan &&
      user.kuchikomiEnterpriseId.toString() === kuchikomi.revieweeEnterprise.id
    ) {
      return true
    }
  }

  return false
}
