import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { UserRanking } from '../../../model/Ranking'
import { RankingPeriod, RankingUserStat } from '../../../clients/api_client'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type UseUserRankingProps = {
  getLimit?: number
}

const useKuchikomiCountUserRanking = ({
  getLimit = 20,
}: UseUserRankingProps): [Array<UserRanking>, boolean] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [ranking, setRanking] = useState<Array<UserRanking>>([])

  const search = useCallback(async () => {
    setIsLoading(true)

    await apiClient.ranking
      .userList({
        period: RankingPeriod.All,
        stat: RankingUserStat.KuchikomiCount,
        limit: getLimit,
      })
      .then((res) => {
        const result = res.data

        if (result.data) {
          setRanking(result.data)
        }
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [ranking])

  useEffect(() => {
    search()
  }, [])

  return [ranking, isLoading]
}

export default useKuchikomiCountUserRanking
