import { useCallback, useEffect, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { UserRanking } from '../../../model/Ranking'
import useFollow from '../../../hooks/use_follow'
import { RankingPeriod, RankingUserStat } from '../../../clients/api_client'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import { useSearchParams } from 'react-router-dom'

type UseUserRankingProps = {
  period: RankingPeriod
  stat: RankingUserStat
  group?: string
  groupValue?: string
  getLimit?: number
}

type Handler = {
  search: (
    period: RankingPeriod,
    stat: RankingUserStat,
    group?: string,
    groupValue?: string,
    cursor?: number
  ) => Promise<void>
  follow: (id: string, set: boolean) => Promise<void>
}

const useUserRanking = ({
  getLimit = 20,
  ...props
}: UseUserRankingProps): [
  Array<UserRanking>,
  RankingPeriod,
  RankingUserStat,
  string | undefined,
  string | undefined,
  number,
  number,
  boolean,
  Handler,
] => {
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [currentPeriod, setCurrentPeriod] = useState<RankingPeriod>(
    props.period
  )
  const [currentStat, setCurrentStat] = useState<RankingUserStat>(props.stat)
  const [currentGroup, setCurrentGroup] = useState<string | undefined>(
    props.group
  )
  const [currentGroupValue, setCurrentGroupValue] = useState<
    string | undefined
  >(props.groupValue)
  const [ranking, setRanking] = useState<Array<UserRanking>>([])
  const [followHandler] = useFollow()
  const [queryParam] = useSearchParams()

  const search = useCallback(
    async (
      period: RankingPeriod,
      stat: RankingUserStat,
      group?: string,
      groupValue?: string,
      cursor = 0
    ) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      setCurrentPeriod(period)
      setCurrentStat(stat)
      setCurrentGroup(group)
      setCurrentGroupValue(groupValue)

      const queryObj = Object.entries({
        period: period,
        stat: stat,
        group: group,
        groupValue: groupValue,
        cursor: cursor,
        limit: getLimit,
      })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, value]) => {
          return value !== undefined && value !== '' && value !== null
        })
        .map(([key, value]) => {
          return `${key}=${value}`
        })
        .join('&')
      window.history.pushState(
        null,
        '',
        window.location.pathname.split('?')[0] + '?' + queryObj
      )

      await apiClient.ranking
        .userList({
          period: period,
          stat: stat,
          group: group,
          groupValue: groupValue,
          cursor: cursor,
          limit: getLimit,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setRanking(result.data)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, ranking]
  )

  const follow = useCallback(
    async (id: string, set: boolean) => {
      // 先に画面更新
      const targetIndex = ranking.findIndex((item) => item.user.id === id)
      const nextRanking = [...ranking]

      nextRanking[targetIndex].user.yourFollower = set
      setRanking(nextRanking)

      // API連携
      await followHandler
        .follow(id, set, 'user')
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return null
        })
    },
    [ranking]
  )

  useEffect(() => {
    const period = queryParam.get('period')
    const stat = queryParam.get('stat')
    const group = queryParam.get('group')
    const groupValue = queryParam.get('groupValue')

    search(
      period ? (period as RankingPeriod) : props.period,
      stat ? (stat as RankingUserStat) : props.stat,
      group ?? undefined,
      groupValue ?? undefined
    )
  }, [])

  return [
    ranking,
    currentPeriod,
    currentStat,
    currentGroup,
    currentGroupValue,
    totalSize,
    cursor,
    isLoading,
    { search: search, follow: follow },
  ]
}

export default useUserRanking
