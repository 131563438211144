import React, { FC } from 'react'
import { EvalurationStarIcon } from '../../images/icons/star_icon'
import Enterprise from '../../model/Enterprise'
import { GivedPointIcon } from '../../images/icons/gived_point_icon'
import { HoldPointIcon } from '../../images/icons/hold_point_icon'
import FlatButton from '../buttons/flat_button'
import { RankFluctuationIcon } from '../../images/icons/rank_fluctuation_icon'
import {
  EnterpriseFieldRanking,
  EnterpriseSDGsRanking,
} from '../../model/Ranking'
import Press from '../../model/Press'
import { Link } from 'react-router-dom'
import { useSdgsImage } from '../../hooks/use_sdgs_image'
import { useAuth } from '../../hooks/use_auth'
import { trimText } from '../../utils/string_util'
import { RankFluctuationText } from '../../images/icons/rank_fluctuation_text'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import LoadingSpinner from '../loading/loading_spinner'

type EnterpriseDetailCardProps = {
  enterprise: Enterprise
  press: Array<Press> | null
  pressLoading: boolean
  follow: (set: boolean) => Promise<void>
}

type RankItemProps = {
  title: string
  rank: EnterpriseFieldRanking
  label?: string
}

const EnterpriseDetailCard: FC<EnterpriseDetailCardProps> = (props) => {
  const { user } = useAuth()
  const isSp = useSpBreakPoint()

  return (
    <div className="md:flex justify-between flex-wrap w-full bg-white rounded-xl">
      {/* 一番左 */}
      <div className="py-4 w-[150px] pl-4">
        <div className="flex md:block">
          <img
            src={props.enterprise.logoUrl}
            alt={props.enterprise.name}
            width={192}
          />
          <div className="max-md:ml-8">
            <div className="flex justify-start">
              <div className="flex flex-col justify-center pr-2">
                <EvalurationStarIcon
                  evaluation={props.enterprise.evaluationAverage}
                />
              </div>
              <p className="text-main-black">
                {props.enterprise.evaluationAverage
                  ? props.enterprise.evaluationAverage.toFixed(2)
                  : '-'}
              </p>
            </div>
            <div>
              <p className="text-xs text-main-darkGray">
                クチコミ件数：{props.enterprise.kuchikomiCount}
              </p>
            </div>
            <div className="pt-4">
              <div className="flex justify-start mb-1">
                <div className="flex flex-col justify-center w-6">
                  <GivedPointIcon />
                </div>
                <p className="text-xs text-main-blue whitespace-nowrap">
                  ポイント贈呈数：
                  {props.enterprise.givedPointCount
                    ? props.enterprise.givedPointCount.toLocaleString()
                    : 0}
                </p>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-col justify-center w-6">
                  <HoldPointIcon />
                </div>
                <p className="text-xs text-main-blue whitespace-nowrap">
                  ポイント保有数：
                  {props.enterprise.holdPointCount
                    ? props.enterprise.holdPointCount.toLocaleString()
                    : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 真ん中 */}
      <div className="border-r-4 border-r-main-gray py-4 md:w-[calc(100%-150px)] lg:w-[calc(100%-450px)] px-4">
        <div className="flex justify-between">
          <p className="text-xl font-bold">{props.enterprise.name}</p>
          {user.isLogined() && user.isKuchikomiUser() && (
            <div>
              <FlatButton
                bgColor="blue"
                onClick={() => {
                  props.follow(!props.enterprise.followed)
                }}
                className={`${
                  props.enterprise.followed && '!bg-main-lightGray'
                } !w-36 !py-1`}
              >
                {props.enterprise.followed ? 'フォロー中' : 'フォローする'}
              </FlatButton>
            </div>
          )}
        </div>
        <div className="flex justify-between py-2">
          <div>
            {props.enterprise.enterpriseCode && (
              <p className="text-xs text-main-darkGray">
                {props.enterprise.enterpriseCode.value}
              </p>
            )}
            <p className="text-xs text-main-darkGray">
              {props.enterprise.industryParentCode.value}
            </p>
            <p className="text-xs text-main-darkGray">
              {props.enterprise.prefecture}
            </p>
          </div>
        </div>
        <div>
          <p className="text-sm text-main-darkGray">
            {props.enterprise.description || ''}
          </p>
        </div>
        <div className="pt-4">
          {props.enterprise.enterpriseCode &&
            props.enterprise.enterpriseRank && (
              <div className="pb-2">
                <RankItem
                  label="団体別クチコミランキング"
                  title={props.enterprise.enterpriseCode.value}
                  rank={props.enterprise.enterpriseRank}
                />
              </div>
            )}
          {props.enterprise.industryParentCode &&
            props.enterprise.industryRank && (
              <div className="pb-2">
                <RankItem
                  label="業界別クチコミランキング"
                  title={props.enterprise.industryParentCode.value}
                  rank={props.enterprise.industryRank}
                />
              </div>
            )}
          {props.enterprise.prefecture && props.enterprise.prefectureRank && (
            <div className="pb-2">
              <RankItem
                label="都道府県別クチコミランキング"
                title={props.enterprise.prefecture}
                rank={props.enterprise.prefectureRank}
              />
            </div>
          )}
          {props.enterprise.sdgsRank &&
            props.enterprise.sdgsRank.length > 0 && (
              <div className="pb-2">
                <SDGsRankItem sdgsRank={props.enterprise.sdgsRank} />
              </div>
            )}
        </div>
      </div>
      {/* 右側 */}
      <div className="py-4 w-full lg:w-[300px]">
        <p className="text-xl px-4 mb-2 font-bold">最新のプレスリリース</p>
        <div className="max-h-[400px] px-4 overflow-y-auto">
          {props.pressLoading ? (
            <LoadingSpinner className="mx-auto mb-16" />
          ) : (
            <>
              {(props.press?.length ?? 0) === 0 && (
                <p className="text-sm py-6 text-center">
                  プレスリリースはありません。
                </p>
              )}
              {props.press?.map((item, index) => {
                return (
                  <Link
                    to={item.url}
                    key={`press-${String(index)}`}
                    target="_blank"
                  >
                    <div className="flex justify-between py-2">
                      <div className="w-3/5">
                        <p className="text-sm text-main-lightGray mb-1">
                          {item.publishDate}
                        </p>
                        <p className="text-bold text-sm leading-relaxed">
                          {trimText(item.title, isSp ? 70 : 30)}
                        </p>
                      </div>
                      <div className="pl-2 w-2/5">
                        <img src={item.mainImageUrl} alt={item.title} />
                      </div>
                    </div>
                  </Link>
                )
              })}
            </>
          )}
          {props.enterprise.profile_id && (
            <div className="text-center pt-4">
              <Link
                target="_blank"
                to={`${process.env.REACT_APP_PR_BASE_URL}/enterprise?enterprise_id=${props.enterprise.profile_id}`}
              >
                <FlatButton bgColor="blue">
                  <p className="text-white leading-4">もっと見る</p>
                </FlatButton>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EnterpriseDetailCard

const RankItem: FC<RankItemProps> = (props) => {
  return (
    <div className="py-2 px-2 bg-main-gray rounded-xl flex justify-start">
      <div className="flex flex-col justify-center w-[42px]">
        <div className="flex justify-center">
          <RankFluctuationIcon type={props.rank.fluctuation} />
        </div>
        <RankFluctuationText type={props.rank.fluctuation} />
      </div>
      <div className="pl-4">
        {props.label && <p className="text-xs opacity-75">{props.label}</p>}
        <p className="text-main-black font-bold">{props.title}</p>
        <div className="flex justify-start">
          <p className="text-main-black font-bold text-lg">
            {String(props.rank.rank)}位
          </p>
          <p className="text-xs text-main-darkGray leading-8">
            {/* （全{String(props.denominator.toLocaleString())}社中） */}
          </p>
        </div>
      </div>
    </div>
  )
}

const SDGsRankItem: FC<{
  sdgsRank: Array<EnterpriseSDGsRanking>
}> = (props) => {
  const [getSdgsImage] = useSdgsImage()

  return (
    <div className="py-2 px-2 bg-main-gray rounded-xl">
      <p className="pl-[58px] text-xs opacity-75 mb-2">
        SDGsクチコミランキング
      </p>
      {props.sdgsRank.map((item, index) => {
        return (
          <div
            key={`sdgs-rank-${String(index)}`}
            className="flex justify-start mb-4"
          >
            <div className="flex flex-col justify-center">
              <div className="flex justify-center w-[42px]">
                <RankFluctuationIcon type={item.fluctuation} />
              </div>
              <RankFluctuationText type={item.fluctuation} />
            </div>
            <div className="flex justify-start pl-4">
              <div className="flex flex-col justify-center">
                <img
                  src={getSdgsImage(item.category.code)}
                  className="w-12 min-w-[3rem]"
                />
              </div>
              <div className="flex flex-col justify-center pl-4">
                <p className="text-main-black font-bold text-lg">
                  {item.category.value}（{item.rank}位）
                </p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
