import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { ArrowIcon } from '../../../../images/icons/arrow_icon'

type RankingTabProps = {
  isSp: boolean
  activeIndex: number
  children: ReactNode
  isFormShow: boolean
  toggleContent: () => void
}

// 検索対象ごとにコンポーネントを分けるようにしているので、タブは見かけだけ用意する
const RankingTab: FC<RankingTabProps> = (props) => {
  const menus = [
    {
      label: `企業・団体${props.isSp ? '' : 'ランキング'}`,
      path: 'enterprise',
    },
    {
      label: `クチコミ${props.isSp ? '' : 'ランキング'}`,
      path: 'kuchikomi',
    },
    {
      label: `ユーザー${props.isSp ? '' : 'ランキング'}`,
      path: 'user',
    },
  ]

  const menuBtns = menus.map((item, index) => {
    const styles = [
      'md:px-6',
      'px-2',
      'py-2',
      'sm:py-1',
      'cursor-pointer',
      'md:text-base',
      'text-sm',
    ]
    if (index === props.activeIndex) {
      styles.push('bg-main-blue')
    } else {
      styles.push('bg-main-lightGray')
    }
    if (index === 0) {
      styles.push('sm:rounded-tl-lg')
    } else if (index === menus.length - 1) {
      styles.push('sm:rounded-tr-lg')
    } else {
      styles.push('border-r border-r-white border-l border-l-white')
    }

    return (
      <Link
        key={`ranking-menu-${item.path}`}
        to={`/ranking/${item.path}`}
        className="w-1/3 sm:w-auto"
      >
        <div
          key={`tab-menu-${String(index)}`}
          className={styles.join(' ')}
          style={{ transitionDuration: '300' }}
        >
          <p className="text-center text-white sm:min-w-[8rem]">{item.label}</p>
        </div>
      </Link>
    )
  })

  return (
    <div className="w-full">
      <div className="flex justify-start">{menuBtns}</div>
      <div className="w-full rounded-bl-xl rounded-br-xl sm:rounded-tr-xl bg-white py-6 px-4">
        <div className="flex justify-between overflow-hidden cursor-pointer">
          <div>
            <div
              className="font-bold"
              style={props.isFormShow ? { height: 'auto' } : { height: 0 }}
            >
              {/* フォームの表示/非表示をここで切り替える */}
              {props.isFormShow && (
                <div className="overflow-hidden">{props.children}</div>
              )}
            </div>
            <div
              className="font-bold"
              style={props.isFormShow ? { height: 0 } : { height: 'auto' }}
            >
              ランキングの種類を選択してください。
            </div>
          </div>
          {/* 「X」ボタン */}
          <div
            className="flex justify-center items-start pt-3"
            onClick={props.toggleContent}
          >
            {/* 状態に応じて ArrowIcon の表示を変える */}
            <ArrowIcon
              className={`transition-transform duration-300 ${
                props.isFormShow ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RankingTab
