import React, { FC } from 'react'

const PRUserPolicyContent: FC = () => {
  return (
    <>
      <p className="mb-12">
        株式会社The Lodges（以下「当社」といいます。）が
        運営するプレスリリース配信サイト「SDGs PR Lodge（エス・ディ・ジーズ
        ピーアール
        ロッジ）」（以下「本サービス」といいます。）をご利用になられる皆様には、利用規約（以下「本規約」といいます。）が適用されます。
        本規約をよくお読みになった上で、本サービスをご利用ください。
      </p>

      <p className="font-bold mb-4">第1条（各規約への同意）</p>

      <div className="mb-12">
        <p>
          1.
          本サービスをご利用頂くにあたっては、適用される各規約の全文をお読み頂いたうえで、そのすべての条件に同意していただく必要があります。
        </p>
        <p>
          2.
          本サービスをご利用頂いた場合、適用される各規約の内容を理解しており、かつ、それらの条件に同意したものとみなします。
        </p>
      </div>

      <p className="font-bold mb-4">第2条（定義）</p>
      <p className="mb-4">
        本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
      </p>
      <div className="ml-8 mb-12">
        <p>
          「本サービス」とは、当社が提供する「SDGs PR Lodge（エス・ディ・ジーズ
          ピーアール
          ロッジ）」に対して、プレスリリースを含むプラットフォームサービス及びこれに関連するすべてのサービスを指します。
        </p>
        <p>
          1.
          「サービス利用契約」とは、本規約を契約条件として当社と会員等の間で締結される、本サービスの利用契約を意味します。情報を掲載する行為や、本サービスにおいて情報を活用する行為だけでなく、情報を閲覧・受信する行為も含みます。ただし、各利用者が本サービスを利用できる範囲は、各規約および当社が別途指定する条件、内容、方法などに限られます。
        </p>
        <p>
          2.
          「本サイト」とは、本サービスを提供するためのWebサイトおよびスマートフォンやタブレット端末用OS向けのアプリケーションソフトウェアを指します。当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
        </p>
        <p>
          3.
          「会員」とは、本規約を承認し、本サービスを利用するために当社所定の入会登録を行い、当社がその入会登録を承認した法人、個人を指します。「会員」には、「ご利用企業」「メディアユーザー」および「一般ユーザー」の３種類のいずれかまたはその複数の資格を有する方を指します。
        </p>
        <p>
          4.
          「ご利用企業」とは、利用規約に同意した上で、当社が指定する方法に従って本サービスの利用登録を申請し、当社が審査して利用登録を承認した企業や団体、または個人事業主を指します。
        </p>
        <p>
          5.
          「メディアユーザー」とは、報道関係者であり、利用規約に同意した上で、当社が指定する方法に従って本サービスの利用登録を申請し、当社が審査して利用登録を承認した方を指します。記者、編集者、ライター、または番組制作者などを含みますが、これらに限定されません。
        </p>
        <p>
          6.
          「一般ユーザー」とは、当社が指定する方法に従って、利用規約に同意し、本サービスの利用登録を申請し、登録された方を指します。
        </p>
        <p>
          7.
          「ゲスト」とは、一般ユーザーと同様の目的で、本サービスを利用するが、本サービスの利用登録を行っていない方を指します。
        </p>
        <p>8. 「当社」とは、株式会社The Lodgesを意味します。</p>
        <p>
          9.
          「投稿データ」とは、会員が本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
        </p>
        <p>
          10.
          「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
        </p>
      </div>

      <p className="font-bold mb-4">第3条（登録）</p>
      <div className="mb-12">
        <p>
          本サービスの利用を希望する方は、本利用規約に同意し、当社が指定する情報（以下「登録情報」といいます）を登録するものとします。
        </p>
        <p>
          当社は、当社の基準に従って、第１項に基づいて登録申請を行った申込者の登録の可否を判断し、当社が登録を認める場合にはその旨を申込者に通知します。申込者の会員としての登録は、当社が本項の通知を行ったことをもって完了したものとします。
        </p>
        <p>
          前項に定める登録の完了時に、サービス利用契約が会員と当社の間に成立し、会員は本サービスを本規約に従い利用することができるようになります。
        </p>
        <p>
          当社は、会員が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
        </p>
        <div className="ml-8">
          <p>
            1.
            当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
          </p>
          <p>
            2.
            未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
          </p>
          <p>
            3.
            反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
          </p>
          <p>
            4.
            過去当社との契約に違反した者またはその関係者であると当社が判断した場合
          </p>
          <p>5. 第10条に定める登録抹消等された場合</p>
          <p>6. その他、登録を適当でないと当社が判断した場合</p>
        </div>
        <p>
          会員は当社の承認ある場合を除き、1社につき1つのアカウントのみ作成することができます。
        </p>
      </div>

      <p className="font-bold mb-4">第4条（登録事項の変更）</p>
      <div className="mb-12">
        <p>
          登録内容の変更がある場合は、会員は、直ちに当社所定の手続により登録内容を修正しなければならず、常に会員自身の正確な情報が登録されているよう、会員は、登録内容を管理・修正する責任を負います。
        </p>
        <p>
          登録情報に変更があった場合には、直ちに当社所定の手続きにより修正しなければならず、常に正確な情報が登録されているよう、会員は登録情報を管理・修正する責任を負います。登録情報に変更があったにもかかわらず、当社所定の手続きにより変更の届出をしていない場合、当社は登録情報に変更がないものとして取り扱うことができます。なお、変更前に行われた取引や手続きについては、変更前の情報に基づくものとします。
        </p>
        <p>
          会員の登録情報に虚偽、誤りまたは記載漏れがあったことにより、本登録者に損害が生じた場合でも、当社は一切責任を負いません。
        </p>
      </div>

      <p className="font-bold mb-4">第5条（アカウント等に関する規定）</p>
      <div className="mb-12">
        <p>
          当社は、会員が本サービスを利用するために必要なアカウントを発行します。会員は、当社の承認がある場合を除き、1名または1法人につき1つのアカウントのみを取得できます。ただし、ご利用企業については、1つの企業アカウントおよび1つのユーザーアカウントの計2種類のアカウントを発行することができます。なお、ユーザーアカウントについては、ご利用企業自身の判断により、アカウント数を増減させることができます。
        </p>
        <p>
          会員に割り当てられたアカウント等は、第三者に譲渡または貸与できず、パスワードを第三者に開示することもできません。会員は、自己の責任でアカウント等を厳重に管理するものとし、第三者の使用や管理不十分による損害について、当社は一切の責任を負いません。
        </p>
        <p>
          会員がアカウント等を利用して行った行為については、会員自身が責任を負います。当社は、アカウント等の管理に関して一切の責任を負いません。
        </p>
        <p>
          会員は、アカウントなどが第三者に使用されたことが分かった場合には、すぐに当社に報告し、当社からの指示に従うことになります。
        </p>
        <p>
          ご利用企業の各ユーザーアカウントを使用する場合、そのユーザーは当該ご利用企業の企業アカウントの管理者と見なされ、当該ユーザーアカウントを使用して行われた全ての行為は当該ご利用企業の行為と見なされます。そのため、当該行為の責任は当該ご利用企業に帰属します。また、ご利用企業は、当社からの利用料金の請求書などを送付する際、企業アカウントまたはユーザーアカウント以外の連絡先を登録することができますが、当社が当該連絡先と円滑に連絡を取ることができないと判断した場合、当社は判断に基づいて、企業アカウントまたはユーザーアカウント（いずれかまたは両方）宛に請求書や連絡、通知などを送信することができます。当社が連絡等を行った場合、当該ご利用企業、企業アカウント、および全てのユーザーアカウントは、当該連絡等が行われたものと見なされます。なお、ご利用企業、当該ご利用企業のユーザーアカウントを使用する方、請求書送付先として登録された方、およびその他の関係者は、本項の取り扱いについて事前に同意し、異議を述べないことに同意するものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第6条（料金及び支払方法）</p>
      <div className="mb-12">
        <p>
          本サービスの利用登録は無料ですが、ご利用企業は、当社が公表する「利用料金プラン表」に基づいて料金を支払う必要があります。
        </p>
        <p>
          ご利用企業が本サイトに掲載する企業コンテンツを取り下げた場合でも、当該コンテンツに関する料金は免除または減額されません。ご利用企業は、全額を当社に支払う必要があります。
        </p>
        <p>
          プラン変更を行う際には、契約プランの終了月の前月の1日から19日までに変更手続きをお願いしております。終了月後にプラン変更の意向があり、かつ前述した期日までに変更手続きが完了していない場合、いかなる理由があっても、本サービスをご利用いただく企業様は全額を当社に利用料を支払う必要があります。
        </p>
        <p>
          利用料金プランの変更を希望する場合、ご利用企業は当社が定める方法に従って申し込む必要があります。当社が申し込みを承認した時点で変更が認められます。通知がなされるまでは、変更前の料金プランに基づいて料金が発生します。
        </p>
        <p>
          契約期間が定められている料金プランを選択した場合、ご利用企業は中途解約できません。利用を中止しても、残りの契約期間に対応する料金の返還は行われません。また、未払いの料金がある場合は全額支払う必要があります。
        </p>
        <p>
          オプション機能を利用する場合は、当社が指定する方法に従って申し込む必要があります。
        </p>
        <p>
          当社は、当月分のサービス利用料金について、翌月の月初3営業日までに、電子メールで請求書を送信します。
        </p>
        <p>
          ご利用企業は、当月分の利用料金について、翌月末日までに当社の指定する方法で、請求書に記載された利用料金（消費税および地方消費税相当額を含む）を支払う必要があります。支払いには、振込手数料および消費税等が含まれます。また、契約期間中に消費税等の変更があった場合には、変更後の税率が適用されます。利用料金を一括払いした後に消費税等が増額した場合は、その差額を負担する必要があります。
        </p>
        <p>
          もし当社が指定する支払期日までに利用料金の支払いが確認できず、または規約で定める事由に該当する場合、ご利用企業は、未払いの利用料金に対して年利14.6％の遅延損害金を支払う必要があります。また、期限の利益を喪失することになります。
        </p>
        <p>
          当社は、利用料金プラン表の内容や価格を変更する場合、事前に告知し、本サイトに掲載してお知らせします。
        </p>
        <p>
          当社は、利用料金等債権の回収業務を、当社が指定する第三者に委託することができます（当該第三者に対して利用料金等債権を債権譲渡することを含みます）。
        </p>
        <p>
          会員が利用料金の支払を遅滞した場合、会員は年14.6％の割合による遅延損害金を当社に支払うものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第7条（禁止事項）</p>
      <div className="mb-12">
        <p>
          会員は、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
        </p>
        <div className="ml-8">
          <p>法令に違反する行為または犯罪行為に関連する行為</p>
          <p>
            当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
          </p>
          <p>公序良俗に反する行為</p>
          <p>
            当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
          </p>
          <p>
            本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
          </p>
          <div className="ml-8">
            <p>過度に暴力的または残虐な表現を含む情報</p>
            <p>
              コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
            </p>
            <p>
              当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
            </p>
            <p>過度にわいせつな表現を含む情報</p>
            <p>差別を助長する表現を含む情報</p>
            <p>自殺、自傷行為を助長する表現を含む情報</p>
            <p>薬物の不適切な利用を助長する表現を含む情報</p>
            <p>反社会的な表現を含む情報</p>
            <p>チェーンメール等の第三者への情報の拡散を求める情報</p>
            <p>他人に不快感を与える表現を含む情報</p>
          </div>
          <p>
            本サービスのネットワークまたはシステム等に過度な負荷をかける行為
          </p>
          <p>
            当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
          </p>
          <p>本サービスの運営を妨害するおそれのある行為</p>
          <p>当社のネットワークまたはシステム等への不正アクセス</p>
          <p>第三者に成りすます行為</p>
          <p>本サービスの他の利用者のIDまたはパスワードを利用する行為</p>
          <p>
            当社の許可なく、本サービス上での宣伝、広告、勧誘、または営業行為
          </p>
          <p>
            当社の許可なく、選挙の事前運動、選挙運動、またはこれらに類似する行為をすること。
          </p>
          <p>当社の許可なく、宗教活動、団体への勧誘行為をすること。</p>
          <p>本サービスの他の利用者の情報の収集</p>
          <p>
            当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
          </p>
          <p>
            当社ウェブサイト上で掲載する本サービス利用に関する利用規約に抵触する行為
          </p>
          <p>反社会的勢力等への利益供与</p>
          <p>面識のない異性との出会いを目的とした行為</p>
          <p>前各号の行為を直接または間接に惹起し、または容易にする行為</p>
          <p>前各号の行為を試みること</p>
          <p>その他、当社が不適切と判断する行為</p>
        </div>
      </div>

      <p className="font-bold mb-4">第8条（本サービスの停止等）</p>
      <div className="mb-12">
        <p>
          当社は、利用者に事前の通知なく、本サービスの内容や仕様を変更することができるものとします。
        </p>
        <p>
          当社は、利用者に事前の通知なく、本サービスの機能を追加、変更または廃止することができるものとします。
        </p>
        <p>
          当社は、以下の場合には利用者に通知することなく、本サービスの提供を中断することができるものとします。
        </p>
        <div className="ml-8">
          <p>①本サービスに必要な機器やシステムの保守点検及び更新を行う場合</p>
          <p>②本サービスでシステム障害が発生した場合</p>
          <p>
            ③火災、停電、天変地異等の不可抗力により本サービスの提供が困難な場合
          </p>
          <p>
            ④その他、不測の事態により、当社が本サービスの提供が困難と判断した場合
          </p>
        </div>
        <p>
          当社は、本条に基づき行った措置により利用者に生じた損害について一切の責任を負わないものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第9条（権利帰属）</p>
      <div className="mb-12">
        <p>
          本サイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
        </p>
        <p>
          会員は、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
        </p>
        <p>
          会員は、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他の会員に対しても、本サービスを利用して会員が投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
        </p>
        <p>
          会員は、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第10条（登録抹消等）</p>
      <div className="mb-12">
        <p>
          当社は、会員が、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該会員について本サービスの利用を一時的に停止し、または会員としての登録を抹消することができます。
        </p>
        <p>本規約のいずれかの条項に違反した場合</p>
        <p>登録事項に虚偽の事実があることが判明した場合</p>
        <p>
          支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
        </p>
        <p>
          当社からの問い合わせその他の回答を求める連絡に対して30日間応答がない場合
        </p>
        <p>第3条に該当する場合</p>
        <p>
          その他、当社が本サービスの利用または会員としての登録の継続を適当でないと判断した場合
        </p>
        <p>
          前項各号のいずれかの事由に該当した場合、会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        </p>
      </div>

      <p className="font-bold mb-4">第11条（休止）</p>
      <div className="mb-12">
        <p>
          会員は、当社所定の手続の完了により、月単位で本サービスから一時休止することができます。
        </p>
        <p>
          会員は、一時休止になると、第６条１項にある利用料金の支払い義務は発生しません。
        </p>
        <p>
          一時休止中の利用者情報の取扱いについては、第16条の規定に従うものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第12条（退会）</p>
      <div className="mb-12">
        <p>
          会員は、当社所定の手続の完了により、本サービスから退会し、自己の会員としての登録を抹消することができます。
        </p>
        <p>
          退会にあたり、当社に対して負っている債務が有る場合は、会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        </p>
        <p>
          退会後の利用者情報の取扱いについては、第16条の規定に従うものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第13条（本サービスの内容の変更、終了）</p>
      <div className="mb-12">
        <p>
          当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。
        </p>
        <p>
          当社が本サービスの提供を終了する場合、当社は会員に事前に通知するものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第14条（免責と損害賠償）</p>
      <div className="mb-12">
        <p>
          当社は、本サービスの変更、中断、不具合、故障などによって利用者に損害が生じた場合でも、一切の責任を負いません。この損害には、間接損害、特別損害、付随損害、派生損害、逸失利益などが含まれますが、これらに限定されません。
        </p>
        <p>
          企業コンテンツは、ご利用企業が投稿するものであり、当社は、企業コンテンツの正確性、確実性、有効性、安全性、適法性など、あらゆる事項について保証しません。また、当該企業コンテンツに基づいて生じた損害についても、当社は一切の責任を負いません。
        </p>
        <p>
          本サービスに関連して利用者と第三者（他の利用者を含む）との間で生じた紛争などについて、当社は一切の責任を負いません。
        </p>
        <p>
          利用者が各規約に違反したり、本サービスに関して当社に損害を与えた場合は、利用者が直ちにその一切の損害を賠償する責任を負います。
        </p>
        <p>
          各規約に定める免責条項が適用されない等の理由により、当社が利用者に対して責任を負う場合は、当社の責任は利用者に直接かつ現実に生じた通常の損害に限定され、かつ、損害賠償額は10万円を上限とします（ただし、直近3カ月以内に当社に対する本サービスの利用料金の支払いがある場合には、当該直近3ヶ月以内の支払合計額が10万円を下回る場合には、当該支払合計額が上限となります）。また、本条を含む各規約の定めにかかわらず、法律上、当社が利用者に対して損害賠償責任を負う場合であって、かつ、当該責任の発生が当社の故意または重過失に基づく場合には、当社は、上記の損害および上限額の範囲内で損害賠償の責任を負うものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第15条（秘密保持）</p>
      <div className="mb-12">
        <p>
          会員は、本サービスに関連して当社が会員に対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第16条（利用者情報の取扱い）</p>
      <div className="mb-12">
        <p>
          当社による会員の利用者情報の取扱いについては、別途当社プライバシーポリシーの定めによるものとし、会員はこのプライバシーポリシーに従って当社が会員の利用者情報を取扱うことについて同意するものとします。
        </p>
        <p>
          当社は、会員が当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、会員はこれに異議を唱えないものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第17条（本規約等の変更）</p>
      <div className="mb-12">
        <p>
          当社は、利用者の同意なしに各規約を変更することができます。本サービスを利用する前には、最新の各規約を確認してください。
        </p>
        <p>
          当社は、各規約を変更する場合、変更内容および変更日を本サイトで告知します。
        </p>
        <p>
          本規約を変更した後、利用者が本サービスを利用する場合、利用者は変更に同意したと見なされます。
        </p>
      </div>

      <p className="font-bold mb-4">第18条（連絡／通知）</p>
      <div className="mb-12">
        <p>
          本サービスに関する問い合わせその他会員から当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から会員に対する連絡または通知は、当社の定める方法で行うものとします。
        </p>
        <p>
          当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、会員は当該連絡または通知を受領したものとみなします。
        </p>
      </div>

      <p className="font-bold mb-4">
        第19条（サービス利用契約上の地位の譲渡等）
      </p>
      <div className="mb-12">
        <p>
          会員は、当社の事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
        </p>
        <p>
          当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに会員の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第20条（分離可能性）</p>
      <div className="mb-12">
        <p>
          本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
        </p>
      </div>

      <p className="font-bold mb-4">第21条（準拠法と合意管轄）</p>
      <div className="mb-12">
        <p>各規約は日本法に準拠するものとします。</p>
        <p>
          各規約または本サービスに関連して利用者と当社の間で問題が生じた場合には、利用者と当社で誠意をもって協議するものとします。
        </p>
        <p>
          前項の協議にもかかわらず解決しなかった場合には、訴額に応じて、神戸地方裁判所または神戸簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </div>

      <p className="mb-12">
        【2023年10月31日制定】
        <br />
        【2024年12月10日改訂】
      </p>
    </>
  )
}

export default PRUserPolicyContent
