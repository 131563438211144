import React, { FC, useEffect, useRef, useState } from 'react'
import { Kuchikomi } from '../../model/Kuchikomi'
import { getKuchikomiDate } from '../../utils/datetime_util'
import { ThreeDotsIcon } from '../../images/icons/three_dots_icon'
import { EvalurationStarIcon } from '../../images/icons/star_icon'
import FavoriteButton from '../buttons/favorite_button'
import CommentButton from '../buttons/comment_button'
import BookmarkButton from '../buttons/bookmark_button'
import { useSdgsImage } from '../../hooks/use_sdgs_image'
import { useAuth } from '../../hooks/use_auth'
import { Link, useNavigate } from 'react-router-dom'
import Flag from '../../images/icons/flag.png'
import DeleteIcon from '../../images/icons/delete_icon.png'
import { useClickAway } from 'react-use'
import { useModal } from 'react-hooks-use-modal'
import ReportModalContent from '../modal/report/report_modal'
import ReportCompleteModalContent from '../modal/report/report_complete_modal'
import { RenderKuchikomiText } from '../kuchikomi/render_kuchikomi_text'
import KuchikomiReplyForm from '../kuchikomi/kuchikomi_reply_form'
import KuchikomiReplyCard from './kuchikomi_reply_card'
import { useSnackBar } from '../../providers/snack_bar_provider'
import FlatButton from '../buttons/flat_button'
import WhiteModalContent from '../modal/white_modal_content'
import {
  checkDisplayReplyButton,
  checkEnableReply,
} from '../../utils/kuchikomi_util'
import MypageLink from '../headers/mypage_link'

type KuchikomiCardProps = {
  kuchikomi: Kuchikomi
  borderBottom?: boolean
  rounded?: boolean
  isSp?: boolean
  sendLike: (kuchikomiId: string, point: number) => void
  onClickBookmark: (kuchikomiId: string, set: boolean) => void
  sendReply: (kuchikomiId: string, replyMessage: string) => void
  deleteKuchikomi: (kuchikomiId: string) => Promise<void>
}

/**
 * クチコミのカード
 */
const KuchikomiCard: FC<KuchikomiCardProps> = ({
  borderBottom = false,
  rounded = false,
  isSp = false,
  ...props
}) => {
  const [isOpenReply, setIsOpenReply] = useState<boolean>(false)
  const [isOpenContextMenu, setIsOpenContextMenu] = useState<boolean>(false)
  const [isCreatingReply, setIsCreatingReply] = useState<boolean>(false)
  const [reportNo, setReportNo] = useState<string>('')
  const [isLarge, setIsLarge] = useState<boolean>(false)
  const [openLarge, setOpenLarge] = useState<boolean>(false)
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()

  const [getSdgsImage] = useSdgsImage()
  const { user } = useAuth()
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const KuchikomiRef = useRef<HTMLDivElement>(null)
  const [ReportModal, openReportModal, closeReportModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const [DeleteModal, openDeleteModal, closeDeleteModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const [
    ReportCompleteModal,
    openReportCompleteModal,
    closeReportCompleteModal,
  ] = useModal('root', {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const [NoPlanModal, openNoPlanModal, closeNoPlanModal] = useModal('root', {
    preventScroll: true,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })

  useClickAway(contextMenuRef, () => {
    if (isOpenContextMenu) {
      setIsOpenContextMenu(false)
    }
  })

  const deleteKuchikomi = async () => {
    props.deleteKuchikomi(props.kuchikomi.id).then(() => {
      showSnackBar(['クチコミを削除しました。'], 'success')
      closeDeleteModal()
    })
  }

  useEffect(() => {
    if (KuchikomiRef.current && KuchikomiRef.current.clientHeight > 200) {
      setIsLarge(true)
    } else {
      setIsLarge(false)
    }
  }, [KuchikomiRef])

  const handleClickReplyButton = () => {
    if (checkEnableReply(user, props.kuchikomi)) {
      setIsCreatingReply(true)
      return
    }

    if (user.isEnterpriseUser() && !user.isPaidPlan) {
      openNoPlanModal()
    }
  }

  return (
    <div
      className={`bg-white py-6 sm:px-4 px-2 ${
        borderBottom ? 'border-b-2 border-b-main-gray' : ''
      } ${rounded ? 'rounded-xl' : ''}`}
    >
      <div className="flex justify-between mb-1">
        <div className="flex justify-start">
          <div className="flex flex-col justify-center">
            <Link
              to={`/users/${props.kuchikomi.reviewer.id}`}
              className={`rounded-full bg-center bg-cover block ml-2 ${
                isSp ? 'w-8 h-8' : 'w-10 h-10'
              }
                `}
              style={{
                backgroundImage: `url(${props.kuchikomi.reviewer.iconUrl})`,
              }}
            />
          </div>
          <div className="flex flex-col justify-center sm:pl-4 pl-2">
            <Link
              to={`/users/${props.kuchikomi.reviewer.id}`}
              className="text-main-black font-bold text-base"
            >
              {`${props.kuchikomi.reviewer.displayName}`}
            </Link>
          </div>
          <div className="flex flex-col justify-center sm:pl-4 pl-2">
            <p className="text-main-lightGray sm:text-sm text-xs">
              {getKuchikomiDate(props.kuchikomi.createdAt)}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center relative">
          <div
            className="cursor-pointer py-2 mr-2"
            onClick={() => setIsOpenContextMenu(!isOpenContextMenu)}
          >
            <ThreeDotsIcon />
          </div>

          {/* コンテキストメニュー */}
          {isOpenContextMenu && (
            <div
              className="absolute outline-1 top-8 right-0 z-20 shadow-md rounded-lg w-52"
              ref={contextMenuRef}
            >
              {!props.kuchikomi.isOwn && (
                <div
                  className="flex items-center py-2 px-4 bg-white hover:bg-main-gray cursor-pointer"
                  onClick={openReportModal}
                >
                  <img src={Flag} alt="通報" className="w-4 h-4 mr-2" />
                  <p className="text-sm">クチコミを通報する</p>
                </div>
              )}
              {props.kuchikomi.isOwn && (
                <div
                  className="flex items-center py-2 px-4 bg-white hover:bg-main-gray cursor-pointer"
                  onClick={() => {
                    openDeleteModal()
                    setIsOpenContextMenu(false)
                  }}
                >
                  <img src={DeleteIcon} alt="通報" className="h-4 mr-2" />
                  <p className="text-sm">クチコミを削除する</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {/* 会社 */}
      <div className="sm:px-4 mx-12 mb-5">
        <div className="flex flex-col justify-center">
          <Link
            className="text-main-blue text-base mb-1 font-bold"
            to={`/enterprises/${props.kuchikomi.revieweeEnterprise.id}`}
          >
            {props.kuchikomi.revieweeEnterprise.name}
          </Link>
        </div>
        <div className="flex flex-col justify-center">
          <EvalurationStarIcon evaluation={props.kuchikomi.evaluation} />
        </div>
      </div>
      {/* クチコミ */}
      <div className="sm:px-4 ml-12 mr-2 mb-6">
        <p
          className={`break-words leading-6 overflow-y-hidden ${
            isLarge && !openLarge ? 'max-h-[210px]' : ''
          }`}
          ref={KuchikomiRef}
        >
          <RenderKuchikomiText
            inputText={props.kuchikomi.kuchikomi}
            onClickHashtag={(hashtag) => {
              const removeHash = hashtag.replace('#', '')
              navigate(`/search/kuchikomi?keyword=${removeHash}`)
            }}
          />
        </p>
        {isLarge && (
          <div className="pt-3 flex items-center text-main-blue ">
            <span className="text-xs mr-1">{openLarge ? '▲' : '▼'}</span>
            {openLarge ? (
              <p
                onClick={() => setOpenLarge(false)}
                className="text-sm cursor-pointer"
              >
                閉じる
              </p>
            ) : (
              <p
                onClick={() => setOpenLarge(true)}
                className="text-sm cursor-pointer"
              >
                続きを見る
              </p>
            )}
          </div>
        )}
      </div>
      {/* ボタン群 */}
      <div className="md:flex md:justify-between md:flex-row-reverse sm:pl-4 ml-12">
        {props.kuchikomi.category === null ? (
          <span></span>
        ) : (
          <Link
            to={`/search/kuchikomi?sdgs-category=${props.kuchikomi.category.code}`}
            className="flex md:justify-end justify-start mb-4 md:mb-0"
          >
            <div className="flex flex-col justify-center">
              <img
                alt={props.kuchikomi.category.value}
                src={getSdgsImage(props.kuchikomi.category.code)}
                width={20}
                height={20}
              />
            </div>
            <div className="flex flex-col justify-center">
              <p className={`text-main-lightGray text-sm pl-2 md:w-[200px]`}>
                {props.kuchikomi.category.value}
              </p>
            </div>
          </Link>
        )}
        <div className="flex justify-start">
          <div className="flex flex-col justify-center mr-2">
            <FavoriteButton
              isLiked={props.kuchikomi.liked}
              isSp={isSp}
              favoriteCount={props.kuchikomi.likeCount}
              sendPoint={
                props.kuchikomi.isOwn
                  ? undefined
                  : (point) => props.sendLike(props.kuchikomi.id, point)
              }
            />
          </div>
          {user.isLogined() &&
            checkDisplayReplyButton(user, props.kuchikomi) && (
              <div className="ml-3 flex flex-col justify-center mr-2">
                <CommentButton
                  isActive={false}
                  isSp={isSp}
                  onClick={() => handleClickReplyButton()}
                />
              </div>
            )}
          {user.isLogined() && (
            <div className="ml-1 flex flex-col justify-center">
              <BookmarkButton
                isActive={props.kuchikomi.bookmarked}
                isSp={isSp}
                onClick={() =>
                  props.onClickBookmark(
                    props.kuchikomi.id,
                    !props.kuchikomi.bookmarked
                  )
                }
              />
            </div>
          )}
        </div>
      </div>

      {/* クチコミの返信を作成中の時 */}
      {isCreatingReply &&
        user.isLogined() &&
        checkEnableReply(user, props.kuchikomi) && (
          <div className="py-4 pl-12">
            <KuchikomiReplyForm
              close={() => setIsCreatingReply(false)}
              sendReply={(message: string) =>
                props.sendReply(props.kuchikomi.id, message)
              }
            ></KuchikomiReplyForm>
          </div>
        )}

      {/* クチコミの返信がある時 */}
      {props.kuchikomi.replies.length > 0 && (
        <div className="pl-4 ml-4 sm:ml-12 mt-6">
          <div
            className="cursor-pointer text-main-blue text-sm flex items-center"
            onClick={() => setIsOpenReply(!isOpenReply)}
          >
            <span className="text-xs mr-1">{isOpenReply ? '▲' : '▼'}</span>
            <span>{props.kuchikomi.replies.length}件のコメント</span>
          </div>
          {isOpenReply && (
            <div className="pt-4">
              {props.kuchikomi.replies.map((reply, rIndex) => (
                <KuchikomiReplyCard
                  key={rIndex}
                  kuchikomi={props.kuchikomi}
                  kuchikomiReply={reply}
                  isSp={isSp}
                  sendReply={(replyMessage) =>
                    props.sendReply(props.kuchikomi.id, replyMessage)
                  }
                />
              ))}
            </div>
          )}
        </div>
      )}
      <ReportModal>
        <ReportModalContent
          kuchikomiId={props.kuchikomi.id}
          closeReportModal={closeReportModal}
          openReportCompleteModal={(reportNo) => {
            setReportNo(reportNo)
            openReportCompleteModal()
          }}
        />
      </ReportModal>
      <ReportCompleteModal>
        <ReportCompleteModalContent
          close={closeReportCompleteModal}
          reportNo={reportNo}
        />
      </ReportCompleteModal>
      <DeleteModal>
        <WhiteModalContent additionalClass="p-8">
          <p className="mb-8 text-center break-keep">
            本当にこのクチコミを
            <wbr />
            削除しますか？
          </p>
          <div className="flex flex-wrap justify-center items-center">
            <FlatButton
              onClick={deleteKuchikomi}
              className="bg-main-blue !mx-2 text-white min-w-[150px] mb-3"
            >
              削除する
            </FlatButton>
            <FlatButton
              onClick={closeDeleteModal}
              className="bg-main-lightGray !mx-2 text-white min-w-[150px] mb-3"
            >
              キャンセル
            </FlatButton>
          </div>
        </WhiteModalContent>
      </DeleteModal>
      <NoPlanModal>
        <WhiteModalContent additionalClass="p-8">
          <div className="mb-8">
            <p>
              従量課金プランのユーザーは自社の口コミに返信することができません。
            </p>
            <p>
              自社の口コミに返信する場合は、マイページから有料プランへの切り替えをお願いします。
            </p>
          </div>
          <FlatButton
            className="!bg-main-lightGray"
            size="md"
            onClick={closeNoPlanModal}
          >
            閉じる
          </FlatButton>
        </WhiteModalContent>
      </NoPlanModal>
    </div>
  )
}
export default KuchikomiCard
