import React, { FC } from 'react'
import Enterprise from '../../model/Enterprise'

type EnterpriseSummaryCardProps = {
  enterprise: Enterprise
}

const EnterpriseSummaryCard: FC<EnterpriseSummaryCardProps> = (props) => {
  return (
    <div className="bg-white py-4 px-2 relative rounded-xl text-sm">
      <div className="border-b border-b-main-gray pb-2 px-1">
        <p className="">{props.enterprise.name}</p>
      </div>
      {props.enterprise.officialUrl && (
        <div className="border-b border-b-main-gray flex justify-start py-2 px-1">
          <p className="min-w-[5rem]">URL</p>
          {props.enterprise.officialUrl ? (
            <a
              href={props.enterprise.officialUrl}
              target="_blank"
              rel="noreferrer"
              className="pl-2 text-main-blue break-all"
            >
              {props.enterprise.officialUrl}
            </a>
          ) : (
            '-'
          )}
        </div>
      )}
      <div className="border-b border-b-main-gray flex justify-start py-2 px-1">
        <p className="min-w-[5rem]">所在地</p>
        <p className="pl-2 text-main-darkGray">
          {props.enterprise.prefecture ?? '-'}
          {props.enterprise.address ?? '-'}
        </p>
      </div>
      <div className="border-b border-b-main-gray flex justify-start py-2 px-1">
        <p className="min-w-[5rem]">電話番号</p>
        <p className="pl-2 text-main-darkGray">{props.enterprise.tel ?? '-'}</p>
      </div>
      <div className="border-b border-b-main-gray flex justify-start py-2 px-1">
        <p className="min-w-[5rem]">代表者名</p>
        <p className="pl-2 text-main-darkGray">
          {props.enterprise.delegateLastName ||
          props.enterprise.delegateFirstName
            ? `${props.enterprise.delegateLastName} ${props.enterprise.delegateFirstName}`
            : '-'}
        </p>
      </div>
      <div className="flex justify-start pt-2 px-1">
        <p className="min-w-[5rem]">設立</p>
        <p className="pl-2 text-main-darkGray">
          {props.enterprise.establishDate
            ? `${props.enterprise.establishDate.split('-')[0]}年${
                props.enterprise.establishDate.split('-')[1]
              }月`
            : '-'}
        </p>
      </div>
    </div>
  )
}

export default EnterpriseSummaryCard
