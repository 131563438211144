import React, { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useToggle } from 'react-use'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useEnterpriseSearchForm from './hooks/use_enterprise_search_form'

import CommonTemplate from '../../components/templates/common_template'
import Head2 from '../../components/heads/head2'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import SearchTab from './components/tab/search_tab'
import EnterpriseSearchForm from './components/form/enterprise_search_form'
import EnterpriseCard from '../../components/card/enterprise_card'
import LoadingSpinner from '../../components/loading/loading_spinner'

// TODO 他検索ページといい感じに共通化したい
// 検索ページのコンポーネント
const EnterpriseSearchPage: FC = () => {
  const PAGE_SIZE = 20 // 1ページあたりの表示件数を定義

  /* ---------------------------------
   * Hooks（状態管理、フック関連）
   --------------------------------- */

  const isSp = useSpBreakPoint() // SP画面かどうか判定
  const [formShow, toggle] = useToggle(true) // フォームの開閉状態を管理
  const [queryParam] = useSearchParams() // URLクエリパラメータを取得

  // 検索に関するデータを取得するカスタムフック
  const [
    companies, // 検索結果（企業情報）
    size, // 全企業の件数
    currentCursor, // 現在のカーソル位置（表示されているデータの範囲）
    isLoading, // ローディング状態
    isSearched, // 検索が実行されたか
    corporateSearchHandler, // 検索フォームのハンドラ
  ] = useEnterpriseSearchForm({ getLimit: PAGE_SIZE })

  // 検索結果にスクロールするためのリファレンス
  const IndexSectionRef = React.createRef<HTMLDivElement>()

  /* ---------------------------------
   * ロジック（ビジネスロジック、検索や操作に関連する部分）
   --------------------------------- */

  // 検索結果セクションにスクロールする関数
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100 // ヘッダー分のオフセット
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  // 検索結果がない場合に検索フォームを自動的に表示
  useEffect(() => {
    if (companies.length === 0) {
      toggle(true)
    }
  }, [companies])

  // URLクエリパラメータがある場合、それを使用して検索を自動的に実行
  useEffect(() => {
    const searchParams = {
      keyword: '',
      sdgsCategory: '',
      enterpriseCategory: '',
      industry: '',
      region: '',
    }
    let isQuery = false // クエリがあるかどうかをチェック

    const querySearchKeyMap = [
      {
        queryParamKey: 'keyword',
        searchParamKey: 'keyword',
      },
      {
        queryParamKey: 'sdgs-category',
        searchParamKey: 'sdgsCategory',
      },
      {
        queryParamKey: 'enterprise-category',
        searchParamKey: 'enterpriseCategory',
      },
      {
        queryParamKey: 'industry',
        searchParamKey: 'industry',
      },
      {
        queryParamKey: 'region',
        searchParamKey: 'region',
      },
    ] as const

    // クエリパラメータに対応する値を検索フォームに設定
    querySearchKeyMap.forEach((item) => {
      if (queryParam.has(item.queryParamKey)) {
        const val = queryParam.get(item.queryParamKey) as string
        corporateSearchHandler.setValue(item.searchParamKey, val)
        searchParams[item.searchParamKey] = val
        isQuery = true // クエリが存在する場合、フラグを立てる
      }
    })

    // クエリパラメータがある場合、検索を実行
    if (isQuery) {
      corporateSearchHandler.submit(searchParams)
    }
  }, [])

  /* ---------------------------------
   * UIコンポーネント（ビジュアル関連）
   --------------------------------- */

  // UIコンポーネント: 検索結果を表示する要素
  const searchResultElem = (
    <div>
      <div className="mb-4 px-4">
        <Head2
          title="企業・団体一覧"
          subTitle={
            companies.length > 0
              ? `全件${String(size)}中 ${currentCursor + 1}~${
                  currentCursor + companies.length
                }件`
              : ''
          }
        />
      </div>
      <div className="flex md:justify-between justify-center flex-wrap">
        {companies.length > 0 ? (
          companies.map((item, index) => {
            return (
              <div
                key={`company-${String(index)}`}
                className="p-2 md:p-3 lg:p-4 w-full md:w-1/2"
              >
                <EnterpriseCard isSp={isSp} enterprise={item} />
              </div>
            )
          })
        ) : (
          <div className="w-full">
            <p className="text-center text-main-lightGray">
              検索結果がありません
            </p>
          </div>
        )}
      </div>
    </div>
  )

  // UIコンポーネント: ページネーション用のコンポーネント
  const paginationCursor =
    companies.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)} // 全ページ数を計算
        numRange={3} // 表示するページ番号の範囲
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1} // 現在のページ番号
        next={() => {
          scrollToIndexSection() // 次ページへスクロール
          corporateSearchHandler.submit(
            corporateSearchHandler.getValues(),
            currentCursor + PAGE_SIZE
          )
        }}
        prev={() => {
          scrollToIndexSection() // 前ページへスクロール
          corporateSearchHandler.submit(
            corporateSearchHandler.getValues(),
            currentCursor - PAGE_SIZE
          )
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection() // 指定ページへスクロール
          corporateSearchHandler.submit(
            corporateSearchHandler.getValues(),
            (pageNum - 1) * PAGE_SIZE
          )
        }}
      />
    ) : null

  // UIコンポーネント: 検索タブとフォーム
  const searchTab = (
    <SearchTab
      isSp={isSp} // SP画面かどうかを伝える
      activeIndex={0} // タブのアクティブ状態
      isFormShow={formShow} // フォームの表示状態
      toggleContent={toggle} // フォームの開閉を切り替える関数
      form={
        <EnterpriseSearchForm
          keywordOptions={corporateSearchHandler.register('keyword')}
          sdgsCategoryOptions={corporateSearchHandler.register('sdgsCategory')}
          enterpriseCategoryOptions={corporateSearchHandler.register(
            'enterpriseCategory'
          )}
          industryOptions={corporateSearchHandler.register('industry')}
          regionOptions={corporateSearchHandler.register('region')}
          onSubmit={corporateSearchHandler.handleSubmit((data) =>
            corporateSearchHandler.submit(data)
          )}
          toggleForm={toggle} // 検索フォームを閉じるためのトグル
        />
      }
    />
  )

  // UIコンポーネント: メインコンテンツ
  const content = (
    <CommonTemplate isPadding={!isSp} isSp={isSp}>
      <div>
        <div className="mt-4 mb-8">{searchTab}</div> {/* 検索タブ */}
        {isLoading && <LoadingSpinner className="mx-auto" />}{' '}
        {/* ローディング中の表示 */}
        {/* 検索済みかつローディング中でない場合、検索結果を表示 */}
        {isSearched && !isLoading && (
          <>
            <div ref={IndexSectionRef} />
            <div className="mb-8">{searchResultElem}</div> {/* 検索結果 */}
            <div className="pb-8">{paginationCursor}</div>{' '}
            {/* ページネーション */}
          </>
        )}
        {/* 検索されていない場合のメッセージ */}
        {!isSearched && (
          <div className="w-full">
            <p className="text-center text-main-lightGray">
              検索条件を入力してください
            </p>
          </div>
        )}
      </div>
    </CommonTemplate>
  )

  return content
}

export default EnterpriseSearchPage
