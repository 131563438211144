import React, { FC, useEffect } from 'react'
import Head2 from '../../components/heads/head2'
import { FormErrorMessage } from '../../constants/message'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useLoading } from '../../providers/loading_provider'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import {
  CheckInputContainer,
  SelectInputContainer,
  SimpleDateInput,
  SimpleInput,
  SimpleTextarea,
  StackInput,
} from '../../components/form/form_item'
import useCodes from '../../hooks/use_codes'
import FlatButton from '../../components/buttons/flat_button'
import { useAuth } from '../../hooks/use_auth'
import { useUpload } from '../../hooks/use_upload'
import { useModal } from 'react-hooks-use-modal'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import RegisterPolicyForm from './register_policy_form'
import WhiteModalContent from '../../components/modal/white_modal_content'

type RegisterFormProps = {
  lastName: string
  firstName: string
  displayName: string
  gender: string
  birthDay: string
  iconUrl: string
  prefecture: string
  profile: string
  catchCopy: string
  occupationCode: string
  interestingSDGs: Array<string>
  homepageUrl: string
  twitterUrl: string
  facebookUrl: string
  instagramUrl: string
  tiktokUrl: string
  email: string
  password: string
  passwordConfirmation: string
  agree: boolean
}

const RegisterUserPage: FC = () => {
  const { showLoading, hideLoading } = useLoading()
  const { showSnackBar } = useSnackBar()
  const { signIn } = useAuth()
  const { upload } = useUpload()
  const [codes] = useCodes()
  const navigate = useNavigate()
  const apiClient = getAppClient()
  const { user } = useAuth()
  const [ConfirmModal, openConfirmModal, closeConfirmModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const isSp = useSpBreakPoint()

  useEffect(() => {
    if (user.isLogined()) {
      showSnackBar(['すでにログインしています。'], 'info')
      navigate('/')
    }
  }, [])

  const { register, handleSubmit, watch, setValue, formState, getValues } =
    useForm<RegisterFormProps>({
      defaultValues: {
        lastName: '',
        firstName: '',
        displayName: '',
        gender: '',
        birthDay: '',
        iconUrl: `${process.env.REACT_APP_PR_BASE_URL}/assets/kuchikomi/img/default_user_profile.png`,
        profile: '',
        catchCopy: '',
        occupationCode: '',
        interestingSDGs: [],
        prefecture: '',
        homepageUrl: '',
        twitterUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        tiktokUrl: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        agree: false,
      },
      mode: 'onSubmit',
    })

  // TODO: ちゃんと表示する
  const { errors } = formState

  const uploadIcon = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files
    if (!files || files?.length === 0) return

    showLoading()
    const nextFile = files[0]
    const url = await upload(nextFile)

    if (url !== null) {
      setValue('iconUrl', url)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const showConfirmModal = async (data: RegisterFormProps) => {
    openConfirmModal()
  }

  const registerUser = async (data: RegisterFormProps) => {
    if (data.password !== data.passwordConfirmation) {
      showSnackBar(['パスワードと確認用パスワードが一致しません'], 'error')
      return
    }

    showLoading()

    const responseData = await apiClient.users
      .registerCreate({
        lastName: data.lastName,
        firstName: data.firstName,
        displayName: data.displayName,
        gender: data.gender,
        birthDay: data.birthDay,
        iconUrl: data.iconUrl,
        email: data.email,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
        profile: data.profile,
        occupationCode: data.occupationCode,
        prefecture: data.prefecture,
        catchCopy: data.catchCopy,
        interestingSDGs: data.interestingSDGs.map((item) => item),
        homepageUrl: data.homepageUrl,
        twitterUrl: data.twitterUrl,
        facebookUrl: data.facebookUrl,
        instagramUrl: data.instagramUrl,
        tiktokUrl: data.tiktokUrl,
      })
      .then((res) => {
        const data = res.data
        return data
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        hideLoading()
      })

    if (responseData === null) {
      return
    }

    // ログインする
    signIn(responseData.user, responseData.token)

    navigate('/register/user/complete')
  }

  const userForm = (
    <div>
      <div className="mb-8">
        <div className="flex justify-start mb-2">
          <div className="rounded-full w-fit">
            <div
              className="w-32 h-32 bg-cover rounded-full bg-center"
              style={{
                backgroundImage: `url(${watch('iconUrl')})`,
              }}
            ></div>
          </div>
          <div className="flex flex-col justify-center ml-4">
            <label
              htmlFor="image-upload"
              className="text-main-lightGray p-2 border border-main-lightGray rounded-lg cursor-pointer"
            >
              データをアップロードする
            </label>
            <input
              id="image-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={uploadIcon}
            />
          </div>
        </div>
        <div>データ形式はjpg、pngのみ対応</div>
      </div>
      {errors.iconUrl && (
        <p className="error-message">{errors.iconUrl.message}</p>
      )}

      <StackInput
        required={false}
        label1="名前（姓）"
        label2="名前（名）"
        description="氏名をご入力ください。"
        options1={register('lastName', {
          // required: FormErrorMessage.required,
          maxLength: {
            value: 50,
            message: FormErrorMessage.maxLength(50),
          },
        })}
        options2={register('firstName', {
          // required: FormErrorMessage.required,
          maxLength: {
            value: 50,
            message: FormErrorMessage.maxLength(50),
          },
        })}
      />
      {errors.lastName && (
        <p className="error-message">{errors.lastName.message}</p>
      )}
      {errors.firstName && (
        <p className="error-message">{errors.firstName.message}</p>
      )}

      <SimpleInput
        required
        label="ユーザー名"
        description="ユーザー名をご入力ください。"
        options={register('displayName', {
          required: FormErrorMessage.required,
          maxLength: {
            value: 50,
            message: FormErrorMessage.maxLength(50),
          },
        })}
      />
      {errors.displayName && (
        <p className="error-message">{errors.displayName.message}</p>
      )}

      <CheckInputContainer
        title="性別"
        keyName="gender"
        type="radio"
        required={false}
        values={[
          {
            options: register(
              'gender'
              //   ,{
              //   required: FormErrorMessage.required,
              // }
            ),
            checks: codes.genderCodes.map((item) => {
              return {
                code: item.code,
                label: item.value,
              }
            }),
          },
        ]}
      />
      {errors.gender && (
        <p className="error-message">{errors.gender.message}</p>
      )}

      <SelectInputContainer
        title="都道府県"
        key="prefecture"
        required={false}
        values={[
          {
            options: register(
              'prefecture'
              //   ,{
              //   required: FormErrorMessage.required,
              // }
            ),
            checks: codes.prefectures.map((p) => {
              return {
                code: p,
                label: p,
              }
            }),
          },
        ]}
      />
      {errors.prefecture && (
        <p className="error-message">{errors.prefecture.message}</p>
      )}

      <SimpleDateInput
        required={false}
        label="誕生日"
        description="誕生日をご入力ください。"
        options={register(
          'birthDay'
          //   , {
          //   required: FormErrorMessage.required,
          // }
        )}
      />
      {errors.birthDay && (
        <p className="error-message">{errors.birthDay.message}</p>
      )}

      <div className="w-full relative">
        <SimpleTextarea
          required={false}
          label="プロフィール"
          description="プロフィールをご入力ください。"
          options={register('profile', {
            // required: FormErrorMessage.required,
            maxLength: {
              value: 3000,
              message: FormErrorMessage.maxLength(3000),
            },
          })}
        />
        {errors.profile && (
          <p className="error-message">{errors.profile.message}</p>
        )}
      </div>

      <div className="w-full relative">
        <SimpleTextarea
          required={false}
          label="ヒトコト"
          description="ヒトコトをご入力ください。"
          options={register('catchCopy', {
            // required: FormErrorMessage.required,
            maxLength: {
              value: 200,
              message: FormErrorMessage.maxLength(200),
            },
          })}
        />
        {errors.catchCopy && (
          <p className="error-message">{errors.catchCopy.message}</p>
        )}
      </div>
      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="ホームページURL"
          description="ホームページURLをご入力ください。"
          options={register('homepageUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.homepageUrl && (
          <p className="error-message">{errors.homepageUrl.message}</p>
        )}
      </div>
      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="X（旧Twitter）URL"
          description="X（旧Twitter）URLをご入力ください。"
          options={register('twitterUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.twitterUrl && (
          <p className="error-message">{errors.twitterUrl.message}</p>
        )}
      </div>
      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="Facebook URL"
          description="Facebook URLをご入力ください。"
          options={register('facebookUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.facebookUrl && (
          <p className="error-message">{errors.facebookUrl.message}</p>
        )}
      </div>
      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="Instagram URL"
          description="Instagram URLをご入力ください。"
          options={register('instagramUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.instagramUrl && (
          <p className="error-message">{errors.instagramUrl.message}</p>
        )}
      </div>
      <div className="w-full relative">
        <SimpleInput
          required={false}
          label="TikTok URL"
          description="TikTok URLをご入力ください。"
          options={register('tiktokUrl', {
            maxLength: {
              value: 250,
              message: FormErrorMessage.maxLength(250),
            },
          })}
          width="100%"
        />
        {errors.tiktokUrl && (
          <p className="error-message">{errors.tiktokUrl.message}</p>
        )}
      </div>

      <CheckInputContainer
        title="職種カテゴリー"
        keyName="occupation"
        type="radio"
        required={false}
        values={[
          {
            options: register(
              'occupationCode'
              //   , {
              //   required: FormErrorMessage.required,
              // }
            ),
            checks: codes.occupationCodes.map((item) => {
              return {
                code: item.code,
                label: item.value,
              }
            }),
          },
        ]}
      />
      {errors.occupationCode && (
        <p className="error-message">{errors.occupationCode.message}</p>
      )}

      <CheckInputContainer
        title="関心のあるSDGsカテゴリー"
        keyName="sdgs"
        type="checkbox"
        optionPosition="block"
        required={false}
        values={[
          {
            options: register('interestingSDGs'),
            checks: codes.sdgsCategories.map((item) => {
              return {
                code: item.code,
                label: item.code + '. ' + item.value,
              }
            }),
          },
        ]}
      />
      {errors.interestingSDGs && (
        <p className="error-message">{errors.interestingSDGs.message}</p>
      )}

      <SimpleInput
        required
        label="メールアドレス"
        type="email"
        description="メールアドレスをご入力ください。"
        options={register('email', {
          required: FormErrorMessage.required,
          maxLength: {
            value: 250,
            message: FormErrorMessage.maxLength(250),
          },
        })}
      />
      {errors.email && <p className="error-message">{errors.email.message}</p>}

      <SimpleInput
        required
        label="パスワード"
        type="password"
        description="パスワードをご入力ください。"
        options={register('password', {
          required: FormErrorMessage.required,
          maxLength: {
            value: 250,
            message: FormErrorMessage.maxLength(250),
          },
          minLength: {
            value: 8,
            message: FormErrorMessage.minLength(8),
          },
        })}
      />
      {errors.password && (
        <p className="error-message">{errors.password.message}</p>
      )}

      <SimpleInput
        required
        label="確認用パスワード"
        type="password"
        description="確認用パスワードをご入力ください。"
        options={register('passwordConfirmation', {
          required: FormErrorMessage.required,
          maxLength: {
            value: 250,
            message: FormErrorMessage.maxLength(250),
          },
        })}
      />
      {errors.passwordConfirmation && (
        <p className="error-message">{errors.passwordConfirmation.message}</p>
      )}

      <RegisterPolicyForm />

      <div className="mb-12">
        <label className="flex items-center justify-center">
          <input
            type="checkbox"
            className="mt-[2px]"
            {...register('agree', {
              required: '必ず同意してください',
            })}
          />
          <span className="pl-1">
            上記の利用規約、プライバシーポリシーについて同意する
          </span>
        </label>
        {errors.agree && (
          <p className="error-message text-center">{errors.agree.message}</p>
        )}
      </div>

      <div className="flex justify-center mt-8">
        <FlatButton
          size="md"
          bgColor="blue"
          type="submit"
          onClick={handleSubmit(showConfirmModal)}
        >
          確認する
        </FlatButton>
      </div>
    </div>
  )

  const confirmContent = (
    <WhiteModalContent additionalClass="w-[800px] p-4 md:p-8">
      <p className="mb-4">以下の内容で会員登録します。</p>
      <div className="mb-8">
        <div className="mb-4">
          <div className="text-main-lightGray">プロフィール画像</div>
          <div>
            <div
              className="w-16 h-16 bg-cover rounded-full bg-center"
              style={{
                backgroundImage: `url(${getValues('iconUrl')})`,
              }}
            ></div>
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">名前</div>
          <div>
            {getValues('lastName')} {getValues('firstName')}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">ユーザー名</div>
          <div>{getValues('displayName')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">性別</div>
          <div>
            {
              codes.genderCodes.find(
                (item) => item.code === getValues('gender')
              )?.value
            }
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">都道府県</div>
          <div>{getValues('prefecture')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">誕生日</div>
          <div>{getValues('birthDay')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">
            プロフィールをご入力ください。
          </div>
          <div>{getValues('profile')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">ヒトコト</div>
          <div>{getValues('catchCopy')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">ホームページURL</div>
          <div>{getValues('homepageUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">X（旧Twitter）URL</div>
          <div>{getValues('twitterUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">Facebook URL</div>
          <div>{getValues('facebookUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">Instagram URL</div>
          <div>{getValues('instagramUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">TikTok URL</div>
          <div>{getValues('tiktokUrl')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">職種カテゴリー</div>
          <div>
            {
              codes.occupationCodes.find(
                (item) => item.code === getValues('occupationCode')
              )?.value
            }
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">SDGsカテゴリー</div>
          <div>
            {getValues('interestingSDGs').map((item) => {
              const sdgsCategory = codes.sdgsCategories.find(
                (sdgs) => sdgs.code === item
              )

              return sdgsCategory ? <p>{sdgsCategory.value}</p> : <></>
            })}
            {/* {getValues('interestingSDGs').map((item) => {
              return codes.sdgsCategories.find(
                (sdgs) => sdgs.code === item.value
              )?.value
            }) || []} */}
          </div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">メールアドレス</div>
          <div>{getValues('email')}</div>
        </div>
        <div className="mb-4">
          <div className="text-main-lightGray">パスワード</div>
          <div>{getValues('password').replace(/./g, '*')}</div>
        </div>
      </div>
      <div className="sm:flex justify-center mt-8">
        <FlatButton
          size="md"
          bgColor="blue"
          type="submit"
          className="mx-auto sm:mx-3 mb-4"
          onClick={handleSubmit(registerUser)}
        >
          会員登録する
        </FlatButton>

        <FlatButton
          size="md"
          bgColor="lightGray"
          onClick={closeConfirmModal}
          className="mx-auto sm:mx-3 mb-4"
        >
          閉じる
        </FlatButton>
      </div>
    </WhiteModalContent>
  )

  const content = (
    <CommonTemplate isSp={isSp}>
      <div className="mb-8">
        <Head2 title="新規会員登録" />
      </div>

      <div className="max-w-[640px] bg-white rounded-xl md:px-4 px-3 py-12 mx-auto">
        {userForm}
      </div>
      <ConfirmModal>{confirmContent}</ConfirmModal>
    </CommonTemplate>
  )

  return <div>{content}</div>
}

export default RegisterUserPage
