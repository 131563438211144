import React, { FC } from 'react'

type HoldPointIconProps = {
  size?: 'sm' | 'md'
  color?: 'blue' | 'white'
}

export const HoldPointIcon: FC<HoldPointIconProps> = ({
  size = 'sm',
  color = 'blue',
}) => {
  let width = '19.629'
  let height = '17.18'
  let bg = '#58a9fa'
  let fill = '#fff'

  switch (size) {
    case 'sm':
      width = '19.629'
      height = '17.18'
      break
    case 'md':
      width = '30.9'
      height = '27.05'
      break
  }

  switch (color) {
    case 'blue':
      bg = '#58a9fa'
      fill = '#fff'
      break
    case 'white':
      bg = '#fff'
      fill = '#58a9fa'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 19.629 17.18"
      >
        <g
          id="グループ_1250"
          data-name="グループ 1250"
          transform="translate(-208.593 -429.768)"
        >
          <path
            id="FontAwsome_heart_"
            data-name="FontAwsome (heart)"
            d="M17.574,33.217a5.775,5.775,0,0,0-7.76.575,5.771,5.771,0,0,0-7.76-.575,5.8,5.8,0,0,0-.406,8.48l6.724,6.851a2.014,2.014,0,0,0,2.883,0L17.98,41.7A5.805,5.805,0,0,0,17.574,33.217Z"
            transform="translate(208.593 397.79)"
            fill={bg}
          />
          <g
            id="グループ_1248"
            data-name="グループ 1248"
            transform="translate(664.334 201.442) rotate(90)"
          >
            <circle
              id="楕円形_43"
              data-name="楕円形 43"
              cx="5.125"
              cy="5.125"
              r="5.125"
              transform="translate(230.682 440.682)"
              fill={fill}
            />
            <path
              id="FontAwsome_arrow-alt-circle-right_"
              data-name="FontAwsome (arrow-alt-circle-right)"
              d="M13.918,8A5.918,5.918,0,1,1,8,13.918,5.917,5.917,0,0,1,13.918,8ZM11.15,14.968h2.768V16.66a.287.287,0,0,0,.489.2l2.728-2.742a.284.284,0,0,0,0-.4l-2.728-2.744a.286.286,0,0,0-.489.2v1.692H11.15a.287.287,0,0,0-.286.286v1.527A.287.287,0,0,0,11.15,14.968Z"
              transform="translate(221.998 431.998)"
              fill={bg}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}
