import React, { FC } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import InnovationImg from '../../images/guide/innovation.png'
import ResponsibilityImg from '../../images/guide/responsibility.png'
import BrandImg from '../../images/guide/brand.png'

const ForEnterprisePage: FC = () => {
  const isSp = useSpBreakPoint()

  const content = (
    <CommonTemplate isSp={isSp} isShowPostButton={false}>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-2">
        SDGs クチコミ Lodgeとは
      </p>
      <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-8">
        企業・団体の方へ
      </p>
      <div className="bg-white rounded-xl w-full lg:px-20 lg:py-12 sm:px-12 px-4 py-8">
        <p className="mb-8">地球は深刻な危機を瀕しております。</p>
        <p className="mb-8">
          今日、私たちが直面している環境問題は、過去のどの時代にも見られないほどの緊急性と重大性を持っています。ヨハン・ロックストロームが提唱した「プラネタリー・バウンダリー」では、私たち人類が生存できる安全な活動領域の閾値を既に超えてしまったと警告しています。気候変動、生物多様性の喪失といった問題は、私たちの生存基盤を揺るがし、地球のレジリエンス（回復力）を危険な限界まで追い込んでいます。
        </p>
        <p className="mb-8">
          2030年までに我々の行動を変えなければ、地球システムの自然回復力を取り戻すことができなくなり、それ以降のどんな努力も無駄に終わってしまうかもしれません。
        </p>
        <p className="mb-8">しかし、希望もあります。</p>
        <p className="mb-8">
          それは、現在の私たちがこの危機を乗り越えるために手を取り合い、持続可能な未来へ共に知恵を出し合い、挑戦を支え合うことです。
        </p>
        <p className="mb-12">
          SDGs クチコミ
          Lodgeは人類が直面する課題について、枠組みを超えて、フラットに話し合う場を提供します。人類が繁栄を続けるために、避けて通れないこの難題に対して、皆でスクラムを組み、One
          Teamになることを目指しております。
        </p>

        <p className="sm:text-4xl text-2xl text-main-blue font-bold text-center mb-16">
          SDGs クチコミ Lodgeがユーザーに選ばれる3つの理由
        </p>
        <p className="lg:text-4xl text-2xl rounded-lg bg-main-blue text-white font-bold p-3">
          選ばれる理由 1
        </p>
        <div className="lg:flex sm:px-8 py-8 mb-12">
          <div className="lg:p-4 lg:mr-8">
            <p className="lg:text-4xl text-2xl text-main-blue font-bold mb-8">
              クチコミは持続可能なイノベーションの源泉
            </p>
            <p className="mb-6">
              SDGsクチコミは、組織内では見過ごされがちな課題や新たなアイデアの宝庫です。ユーザーからの貴重なフィードバックは、製品やサービス、さらにはサプライチェーンの見直しにおける重要な指針となり得ます。組織の活性化を促し、持続可能なイノベーションの火を灯しましょう。
            </p>
          </div>
          <img
            src={InnovationImg}
            className="mt-4 lg:ml-0 ml-auto lg:w-64 lg:h-64 w-full h-full"
          />
        </div>
        <p className="lg:text-4xl text-2xl rounded-lg bg-main-blue text-white font-bold p-3">
          選ばれる理由 2
        </p>
        <div className="lg:flex sm:px-8 py-8 mb-12">
          <div className="lg:p-4 lg:mr-8">
            <p className="lg:text-4xl text-2xl text-main-blue font-bold mb-8">
              社会的責任を果たす企業としての位置づけ
            </p>
            <p className="mb-6">
              SDGsクチコミLodgeは、The Lodgesが運営するSDGs PR
              Lodgeと連携しています。貴社のSDGsへの取り組みを通じて、社会的課題解決に貢献する積極的な役割をアピールし、企業価値の向上につなげましょう。
            </p>
          </div>
          <img
            src={ResponsibilityImg}
            className="mt-4 lg:ml-0 ml-auto lg:w-64 lg:h-64 w-full h-full"
          />
        </div>
        <p className="lg:text-4xl text-2xl rounded-lg bg-main-blue text-white font-bold p-3">
          選ばれる理由 3
        </p>
        <div className="lg:flex sm:px-8 py-8">
          <div className="lg:p-4 lg:mr-8">
            <p className="lg:text-4xl text-2xl text-main-blue font-bold mb-8">
              信頼構築とブランド価値の向上
            </p>
            <p className="mb-6">
              今日、ユーザーは企業・団体に透明性と誠実さを求めています。SDGsへの真摯な取り組みにより、社会的責任を果たす企業・団体として、顧客との信頼を築き、強固な関係を構築することが可能です。エシカルな消費を重視する顧客層と深い絆を結び、長期的なロイヤルティを獲得しましょう。
            </p>
          </div>
          <img
            src={BrandImg}
            className="mt-4 lg:ml-0 ml-auto lg:w-64 lg:h-64 w-full h-full"
          />
        </div>
      </div>
    </CommonTemplate>
  )
  return content
}

export default ForEnterprisePage
